import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Tab from '../../Utilities/Tab';
import Button from '../../Utilities/Button';
import Arrow from '../../Utilities/Arrow';
import { LangData } from '../../Utilities/Props';

import '../../../styles/Form.css';
import '../../../styles/Dashboard.css';

import HeaderForm from './HeaderForm';
import Input from './Input';

import { InputType } from '../../../types/Types';
import { FormType } from '../../../types/Form';

import { submitForm } from '../../../helpers/forms';

import { useLangContext } from '../../../contexts/LangContext';

import allData from '../../../data.json';
import GenerateQuestionButton from './GenerateQuestionButton';


export default function Home() {
    const data = allData['Form'];
    const lang = useLangContext().lang;

    const navigate = useNavigate();
    const [ inputs, setInputs ] = useState<InputType[]>([]);
    const [ form, setForm ] = useState<FormType>({} as FormType);

    function addInput() {
        setInputs([...inputs, { title: '', importance: 0, type: 'text' }]);
    }

    async function saveForm() {
        const formId = await submitForm({form, inputs});

        return formId;
    }

    function handleClick() {
        saveForm().then((formId) => {
            navigate(`/dashboard/attendees/${formId}`);
        });
    }

    return (
        <Tab title="Form">

            <HeaderForm form={form} setForm={setForm} data={data["header"]}/>

            <div className='bloc'>
                <div className='buttons'>
                    <Button onClick={addInput} title={(data["newInput"] as LangData)[lang]}/>
                    <GenerateQuestionButton form={form} inputs={inputs} setInputs={setInputs} data={data["generateQuestion"]}/>
                </div>

                <div className='inputs'>
                    {inputs.map((input, index) => (
                        <Input key={index} inputs={inputs} index={index} setInputs={setInputs} data={data['input']}/>
                    ))}
                </div>
            </div>
            
            <div className='bloc buttons'>
                <Button title={(data["saveForm"] as LangData)[lang]} onClick={saveForm}/>
                
                <Button title={(data["addAttendees"] as LangData)[lang]} onClick={handleClick} rightIcon={<Arrow/>}/>
            </div>
        </Tab>
    );
}
