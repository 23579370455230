import React from "react";

import Button from "./Button";
import Arrow from "./Arrow";

import "../../styles/icon.css";


interface BackProps {
    title?: string;
    className?: string;
    to?: string;
}

export default function Back({title, className, to} : BackProps) {
    return (
        <Button 
            to={to ? to : '/'}
            title={title ? title : 'Back'}
            className={className ? `back ${className}` : 'back'}
            leftIcon={<Arrow className='back-arrow'/>}
        />
    )
}
