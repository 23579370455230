import React from "react";
import Tab from "../Utilities/Tab";

import '../../styles/Dashboard.css';

export default function Placeholder() {
    return (
        <Tab title="Placeholder">
            <img src='/placeholder.jpg' alt="Placeholder"/>
        </Tab>
    )
}