import React from "react";

import { BlocProps } from "../../../types/Types";
import { useLangContext } from "../../../contexts/LangContext";


export interface CardData extends BlocProps {
    bg?: string;
}

interface CardProps {
    data: CardData;
}

export default function Card({ data } : CardProps) {
    const lang = useLangContext().lang;

    const {title, subtitle, description, bg} = data;

    return (
        <div
            className="card"
            style={bg ? {
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            } : undefined}
        >
            <div className="text">
                <h2>{title[lang]}</h2>
                <h3>{subtitle? subtitle[lang] : null}</h3>
                <p>{description[lang]}</p>
            </div>
        </div>
    )
}
