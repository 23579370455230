import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';


import '../../styles/size.css';
import '../../styles/color.css';


interface ButtonProps {
    to?: string;
    title: string;
    rightIcon?: ReactNode;
    leftIcon?: ReactNode;
    className?: string;
    type?: 'button' | 'submit' | 'reset';

    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}


export default function Button({ to, onClick, onMouseEnter, onMouseLeave, title, rightIcon, leftIcon, className }: ButtonProps) {
    const content = (
        <>
            {leftIcon &&
                <div className='icon-container'>
                    {leftIcon}
                </div>
            }

            <div>
                <span>{title}</span>
            </div>

            {rightIcon &&
                <div className='icon-container'>
                    {rightIcon}
                </div>
            }
        </>
    );

    return to ? (
        <Link to={to} className={`button link-button ${className}`} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {content}
        </Link>
    ) : (
        <button className={`button ${className}`} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {content}
        </button>
    );
}
