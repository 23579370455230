import React from 'react';
import { Field, ErrorMessage } from 'formik';

interface InputProps {
    name?: string;
    type?: string;
    htmlFor: string;
    className?: string;
}

function transformCamelCaseToTitleCase(camelCaseString: string): string {
    const words = camelCaseString.match(/[A-Z][a-z]+|[a-z]+/g);
    if (!words) {
        return camelCaseString;
    }
    const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return titleCaseWords.join(' ');
}

export default function Input({ name, type, htmlFor, className }: InputProps) {
    name = name ||  transformCamelCaseToTitleCase(htmlFor);

    return (
        <div className={`input-${htmlFor} ${className}`}>
            {name && 
                <label htmlFor={htmlFor}>
                    {name}
                </label>
            }

            <Field name={htmlFor} type={type || "text"}/>
            <ErrorMessage name={htmlFor} component="div" className="error" />
        </div>
    );
}
