import React from "react";

import { ExtendedInputType } from "../../types/Types";


interface InputProps {
    input: ExtendedInputType;
    setCurrentInputs: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    currentInputs: Record<string, string>;
}


export default function Input({ input, setCurrentInputs, currentInputs } : InputProps) {

    function updateInput(e: React.ChangeEvent<HTMLInputElement>) {
        const updatedInputs = { ...currentInputs };
        updatedInputs[input.id] = e.target.value;
        setCurrentInputs(updatedInputs);
    }

    return (
        <div className="input">
            <div className="head">
                <span style={{background:`radial-gradient(circle at center, red, blue ${(input.importance + 100) / 2}%)`}}>
                    {input.importance}
                </span>
                <label>{input.title}</label>
            </div>
            
            <input
                type={input.type}
                placeholder="Answer"
                onChange={updateInput}
                value={currentInputs[input.id] || ''}
            />
        </div>
    );
}
