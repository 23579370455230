// CSVToJson.tsx

export function CSVToJson(data : string[][]) {
    const headers = data[0];
    const jsonData = data.slice(1).map((row) => {
        return row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
        }, {} as Record<string, string>);
    });

    return jsonData;
}
