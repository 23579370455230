import React from "react";

import HarmateLogo from "../Utilities/HarmateLogo";
import Button from "../Utilities/Button";
import { LangData } from "../Utilities/Props";
import { useLangContext } from "../../contexts/LangContext";
import { DataProps } from "../../types/Types";


export default function Nav({ data } : DataProps) {
    const lang = useLangContext().lang;

    return(
        <nav>
            <HarmateLogo/>

            <div className="buttons">
                <Button to="/signIn" title={(data['signIn'] as LangData)[lang]}/>
                <Button to="/signUp" title={(data['signUp'] as LangData)[lang]} className="signup"/>
            </div>
        </nav>
    )
}
