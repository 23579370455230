// CSVParser.tsx

import React, { useState, useEffect, useReducer } from "react";

import Tab from "../../Utilities/Tab";
import Button from "../../Utilities/Button";

import '../../../styles/CSVParser.css';
import '../../../styles/Dashboard.css';

import HeaderForm from "../Form/HeaderForm";

import Upload from "./Upload";
import Questions from "./Questions";
import Primary from "./Primary";

import { FormType } from "../../../types/Form";
import { dataType, fileType } from "../../../types/Upload";
import { InputType } from "../../../types/Types";

import { submitForm } from "../../../helpers/forms";
import { postAnswersWithoutAttendees } from "../../../helpers/answers";
import { CSVToJson } from "../../../helpers/CSVToJson";
import { computeGroups } from "../../../helpers/compute";

import allData from '../../../data.json';

import Groups from "../Results/Groups";
import Volumetry from "../Results/Volumetry";

import { computeReducer, initialComputeState, ResType } from "../../../reducers/Compute";


export default function CSVParser() {
    const [ computeState, computeDispatch ] = useReducer(computeReducer, initialComputeState);

    const [ dataFile, setDataFile ] = useState<dataType>(null);
    const [ file, setFile ] = useState<fileType>();

    const [ dataSent, setDataSent ] = useState(false);

    const [ form, setForm ] = useState<FormType>({} as FormType);
    const [ headers, setHeaders ] = useState<string[]>([]);
    const [ inputs, setInputs ] = useState<InputType[]>([]);
    const [ selectedHeaders, setSelectedHeaders ] = useState<string[]>([]);

    useEffect(() => {
        if (dataFile && dataFile.length > 0) {
            setHeaders(dataFile[0]);
        }

        if (file) {
            setForm({
                title: file.name,
                description: '',
                fixedSize: 2,
            } as FormType);
        }

    }, [dataFile, file]);


    async function handleCompute() {
        setDataSent(false);
        const formId = await submitForm({form, inputs});
        setForm({...form, id: formId});

        if (formId && dataFile) {
            const jsonData = CSVToJson(dataFile);
            await postAnswersWithoutAttendees(jsonData, formId);
            setDataSent(true);
        }
    }

    useEffect(() => {
        if (dataSent && form.id) {
            computeGroups(form.id).then((response) => {
                computeDispatch({ type: "SET_GROUPS", payload: response.groups });
                computeDispatch({ type: "SET_QUESTIONS", payload: response.questions });
                computeDispatch({ type: "SET_RES_TYPE", payload: response.res_type as ResType });
            });
        }
    }, [dataSent]);

    return (
        <Tab title="CSVParser">
            <Upload setDataFile={setDataFile} setFile={setFile}/>

            {
                dataFile && dataFile.length > 0 && (
                    <>
                        <HeaderForm form={form} setForm={setForm} data={allData['Form']['header']}/>
                        <Questions questions={headers} data={allData['Form']['input']} inputs={inputs} setInputs={setInputs}/>
                        <Primary 
                            file={dataFile}
                            selectedHeaders={selectedHeaders}
                            setSelectedHeaders={setSelectedHeaders}
                        />

                        <Button title="Compute" onClick={handleCompute}/>
                        
                        {
                            dataSent &&
                                <>
                                    <Groups computeState={computeState} primaryKeys={selectedHeaders} form={form}/>
                                    <Volumetry form={form}/>
                                </>
                        }
                    </>
                )
            }
        </Tab>
    )
}
