import React from "react";
import Button from "./Button";
import Cookies from "js-cookie";
import { logOut } from "../../helpers/signIn";


export default function Disconnection() {
    function handleDisconnection() {
        logOut().then(() => {
            Cookies.remove('token');
            console.log("Logged out");
        });

        window.location.href = '/';
    }

    return (
        <Button title="Disconnect" onClick={handleDisconnection}/>
    )
}
