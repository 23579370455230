// Questions.tsx

import React, { useEffect } from "react";
import { InputType } from "../../../types/Types";
import Input from "../Form/Input";

import '../../../styles/Form.css';
import { BlocProps, LangData } from "../../Utilities/Props";


interface QuestionsProps {
    questions: string[];
    data: Record<string, LangData | BlocProps>;
    inputs: InputType[];
    setInputs: (inputs: InputType[]) => void;
}

export default function Questions({ questions, data, inputs, setInputs }: QuestionsProps) {
    useEffect(() => {
        const currentInputs : InputType[] = [];

        questions.map((question) => {
            currentInputs.push({
                title: question,
                importance: 0,
                type: 'text'
            });
        });

        setInputs(currentInputs);

    }, [questions]);

    return (
        <div className="bloc">
            <div className='inputs'>
                {inputs.map((input, index) => (
                    <Input
                        key={index}
                        inputs={inputs}
                        index={index}
                        setInputs={setInputs}
                        data={data}
                    />
                ))}
            </div>
        </div>
    )
}
