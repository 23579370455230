// Input.tsx

import React, { useState } from "react";
import Cross from "../../Utilities/Cross";
import { InputType } from "../../../types/Types";

import "../../../styles/Form.css";
import { useLangContext } from "../../../contexts/LangContext";
import { BlocProps, LangData } from "../../Utilities/Props";
import InformationLogo from "../../Utilities/InformationLogo";


interface RangeLabelProps {
    data: BlocProps;
}

function RangeLabel({ data } : RangeLabelProps) {
    const lang = useLangContext().lang;


    const [showInfo, setShowInfo] = useState(false);

    const handleMouseEnter = () => {
        setShowInfo(true);
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    const handleClick = () => {
        setShowInfo(!showInfo);
    };


    return (
        <div className="range-label-container">
            <span className="range-label">{data.title[lang]}</span>

            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <InformationLogo/>
                {showInfo && (
                    <div className="info-popup">
                        <span>{data.title[lang]}</span>
                        <p>{data.description[lang]}</p>
                    </div>
                )}
            </div>
            
        </div>
    )
}


interface InputProps {
    inputs: InputType[];
    setInputs: (inputs: InputType[]) => void;
    index: number;
    data: Record<string, LangData | BlocProps>;
}

export default function Input({ inputs, index, setInputs, data }: InputProps) {
    const lang = useLangContext().lang;

    function updateInputField(field: keyof InputType, value: number | string) {
        const updatedInputs = [...inputs];
        updatedInputs[index] = { ...updatedInputs[index], [field]: value };
        setInputs(updatedInputs);
    }

    function removeInput() {
        const updatedInputs = [...inputs];
        updatedInputs.splice(index, 1);
        setInputs(updatedInputs);
    }

    return (
        <div className="input-container">
            <div className="icon-container" onClick={removeInput}>
                <Cross/>
            </div>

            <div className="input-entries">
                <div className="input">
                    <input 
                        className="title-input" 
                        type="text"
                        placeholder="New Input"
                        value={inputs[index].title}
                        onChange={e => updateInputField("title", e.target.value)}
                    />
                    
                </div>

                <div className="settings">
                    <RangeLabel data={data["complementary"] as BlocProps}/>

                    <input
                        type="range"
                        min="-100"
                        max="100"
                        step="1"
                        value={inputs[index].importance}
                        onChange={e => updateInputField("importance", parseInt(e.target.value))}
                    />

                    <RangeLabel data={data["similar"] as BlocProps}/>

                    <input 
                        className="importance-label"
                        value={inputs[index].importance}
                        onChange={e => updateInputField("importance", parseInt(e.target.value))}
                    />

                    <select value={inputs[index].type} onChange={e => updateInputField("type", e.target.value)}>
                        <option value="text">{(data['text'] as LangData)[lang]}</option>
                        <option value="number">{(data['number'] as LangData)[lang]}</option>
                        <option value="date">{(data['date'] as LangData)[lang]}</option>
                        <option value="email">{(data['email'] as LangData)[lang]}</option>
                        <option value="tel">{(data['tel'] as LangData)[lang]}</option>
                        <option value="url">{(data['url'] as LangData)[lang]}</option>
                    </select>
                </div>

            </div>
        </div>
    )
}
