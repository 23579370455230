// Groups.tsx

import React, { useEffect, useState } from "react";

import { ComputeState } from "../../../reducers/Compute";
import Group from "./Group";
import { FormType } from "../../../types/Form";

interface GroupsProps {
    computeState: ComputeState;
    primaryKeys : string[];
    form : FormType;
}

export default function Groups({ computeState, primaryKeys, form } : GroupsProps) {
    const [ displayGroups, setDisplayGroups ] = useState<Record<string, string>[][]>([]);

    useEffect(() => {
        const newDisplayGroups : Record<string, string>[][] = [];
        
        computeState.groups.forEach((group) => {
            const currentGroup : Record<string, string>[] = [];

            group.forEach((attendee) => {
                const currentAttendee : Record<string, string> = {};

                primaryKeys.forEach((key) => {
                    const question = computeState.questions.find((question) => question.question === key);
                    if (question) {
                        const response = attendee.answer.find((response) => response.question_id === question.id);
                        if (response) {
                            currentAttendee[key] = response.response;
                        }
                    }
                });

                currentGroup.push(currentAttendee);
            });

            newDisplayGroups.push(currentGroup);
        });

        setDisplayGroups(newDisplayGroups);

    }, [computeState.groups, computeState.questions, computeState.resType, primaryKeys]);

    return (
        <div className="widget groups">
            <h3>Groups</h3>

            <div className="groups-container">
                {displayGroups.map((group, index) => (
                    <Group key={index} index={index} group={group} form={form}/>
                ))}
            </div>
        </div>
    );
}
