// answers.tsx

import axios from 'axios';

import { getApiConfig } from './utils';


export function postAnswer(answer: Record<string, string>, uuid: string) {
    const config = getApiConfig({ endpoint: 'postAnswer' });

    axios.post(config.backendUrl, {
        answer,
        uuid
    }, {
        headers: {
            Authorization: config.token
        }
    });
}


export async function postAnswersWithoutAttendees(answers: Record<string, string>[], formId: number) {
    const config = getApiConfig({ endpoint: 'postAnswersWithoutAttendees' });

    return axios.post(config.backendUrl, {
        answers,
        formId
    }, {
        headers: {
            Authorization: config.token
        }
    });
}


export async function getAnswers(formId: number) {
    const config = getApiConfig({ endpoint: 'getAnswers' });

    const response = await axios.get(config.backendUrl,
        {
        params: {
            formId
        },
        headers: {
            Authorization: config.token
        }
    });

    return response.data;
}
