// Attendee.tsx

import React, { useEffect } from "react";
import { Formik, Form } from 'formik';
import { ObjectSchema } from "yup";

import Cross from "../../Utilities/Cross";
import Input from "../../Utilities/Input";

import "../../../styles/Attendees.css";
import { AttendeeType, AttendeeWithId } from "../../../types/Attendee";

interface AttendeeProps {
    attendee: AttendeeWithId;
    attendees: AttendeeWithId[];
    setAttendees: React.Dispatch<React.SetStateAction<AttendeeWithId[]>>;
    validationSchema: ObjectSchema<AttendeeType>;
}

export default function Attendee({ attendee, attendees, setAttendees, validationSchema }: AttendeeProps) {
    function updateValues(values: AttendeeType) {
        const updatedValues = attendees.map((a) =>
            a.id === attendee.id ? { ...a, ...values } : a
        );
        setAttendees(updatedValues);
    }

    function removeAttendee() {
        const updatedAttendees = attendees.filter(a => a.id !== attendee.id);
        setAttendees(updatedAttendees);
    }
    
    return (
        <Formik
            initialValues={attendee}
            validationSchema={validationSchema}
            onSubmit={() => {}}
            validateOnChange={true}
        >
            {({ values, validateForm }) => {
                useEffect(() => {
                    validateForm(values).then(errors => {
                        if (Object.keys(errors).length === 0) {
                            updateValues(values);
                        }
                    });
                }, [values, validateForm]);

                return (
                    <Form className="attendee-container">
                        <div className="icon-container" onClick={removeAttendee}>
                            <Cross />
                        </div>

                        <Input htmlFor="firstName" />

                        <Input htmlFor="lastName" />

                        <Input htmlFor="email" />
                    </Form>
                );
            }}
        </Formik>
    );
}
