// Slider.tsx

import React, { useEffect, useState, useRef } from "react";

import Card, { CardData } from "./Card";

import "../../../styles/Slider.css";


interface IndicatorProps {
    active: number;
    nb: number;
    sliderRef: React.RefObject<HTMLDivElement>;
}

function Indicator({active, nb, sliderRef} : IndicatorProps) {
    function weight(advancement: number, index: number) {
        return Math.exp(-(((nb - 4) * 20 + 20)* ((index / (nb - 1)) - advancement) ** 2));
    }

    function handleClick(i : number) {
        console.log(i, active);

        if (!sliderRef.current) {
            return;
        }

        sliderRef.current.scrollLeft = (i / (nb - 1)) * (sliderRef.current.scrollWidth - sliderRef.current.clientWidth);
    }

    const bubbles = Array.from({length: nb}, (_, i) => (
        <div
            key={i}
            onClick={() => handleClick(i)}
            style={{width: `calc(${weight(active, i)} * 10 * var(--scale))`}}
            className="bubble"
        />
    ));

    return (
        <div className="indicator">
            {bubbles}
        </div>
    )
}


interface SliderProps {
    data: CardData[];
    title?: string;
    className?: string;
}

export default function Slider({ data, title, className } : SliderProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [scrollWidth, setScrollWidth] = useState(0);

    useEffect(() => {
        function handleScroll() {
            if (!ref.current) {
                return;
            }
            setScrollWidth(ref.current.scrollLeft / (ref.current.scrollWidth - ref.current.clientWidth));
        }

        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === 'ArrowRight') {
                ref.current?.scrollBy({left: 100, behavior: 'smooth'});
            } else if (event.key === 'ArrowLeft') {
                ref.current?.scrollBy({left: -100, behavior: 'smooth'});
            }
        }

        ref.current?.addEventListener('scroll', handleScroll);
        ref.current?.addEventListener('keydown', handleKeyDown);

        return () => {
            ref.current?.removeEventListener('scroll', handleScroll);
            ref.current?.removeEventListener('keydown', handleKeyDown);
        }
    });


    const cards = Object.values(data).map((cardData, index) => (
        <Card key={index} data={cardData}/>
    ));


    return (
        <div className={className ? `slider ${className}` : 'slider'}>
            {title && <h2 className="title">{title}</h2>}

            <div className="cards" ref={ref}>
                {cards}
            </div>

            <Indicator active={scrollWidth} nb={cards.length} sliderRef={ref}/>
        </div>
    )
}
