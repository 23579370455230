import React, { useEffect, useState } from "react";
import { FormType } from "../../../types/Form";
import { LangData } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";
import Size, { SizeType } from "./Size";


interface HeaderProps {
    form : FormType;
    setForm : (form : FormType) => void;
    data: Record<string, LangData>;
}

export default function Header({ form, setForm, data } : HeaderProps) {
    const lang = useLangContext().lang;

    const [ size, setSize ] = useState({} as SizeType);

    useEffect(() => {
        if (size.fixe) {
            setForm({...form, fixedSize: size.fixe});
        }
        
        if (size.min && size.max) {
            setForm({...form, minSize: size.min, maxSize: size.max});
        }

    }, [size]);

    return (
        <div className='bloc description'>
            <h1>{data['title'][lang]}</h1>

            <div className="header">
                <label>Title</label>
                <input
                    type="text"
                    placeholder="Title"
                    value={form.title}
                    onChange={e => setForm({...form, title: e.target.value})}
                />

                <label>Description</label>
                <input
                    type="text"
                    placeholder="Description"
                    value={form.description}
                    onChange={e => setForm({...form, description: e.target.value})}
                />
            </div>

            <Size size={size} setSize={setSize}/>
        </div>
    );
}
