// Results.tsx

import React, { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import Button from "../../Utilities/Button";
import Tab from "../../Utilities/Tab";

import { getAnswers } from "../../../helpers/answers";
import { getForm } from "../../../helpers/forms";
import { computeGroups } from "../../../helpers/compute";

import { FormType } from "../../../types/Form";

import Groups from "./Groups";
import Volumetry from "./Volumetry";

import "../../../styles/Results.css";
import { computeReducer, initialComputeState, ResType } from "../../../reducers/Compute";



export default function Results() {
    const { formId } = useParams<{ formId: string }>();
    const [ computeState, computeDispatch ] = useReducer(computeReducer, initialComputeState);

    const [ answers, setAnswers ] = useState([]);
    const [ attendees, setAttendees ] = useState([]);
    const [ form, setForm ] = useState({} as FormType);


    const primaryKeys = ["firstName", "lastName", "email"];


    function updateAnswers() {
        if (formId) {
            getAnswers(parseInt(formId)).then((response) => {
                setAnswers(response.answers);
                setAttendees(response.attendees);
                console.log("ANSWERS", answers);
                console.log("ATTENDEES", attendees);
            });

            getForm(parseInt(formId)).then((response) => {
                setForm(response);
            });
        }
    }


    function handleCompute() {
        if (formId) {
            computeGroups(parseInt(formId)).then((response) => {
                computeDispatch({ type: "SET_GROUPS", payload: response.groups });
                computeDispatch({ type: "SET_QUESTIONS", payload: response.questions });
                computeDispatch({ type: "SET_RES_TYPE", payload: response.res_type as ResType });
            });
        }
    }

    useEffect(() => {
        updateAnswers();
        handleCompute();
    }, [ formId ]);


    return (
        <Tab title="Results">
            <div className="widget header">
                <h1>Results</h1>

                <h2>
                    {form.title}
                </h2>

                <Button title="Refresh" onClick={updateAnswers}/>
                <Button title="Compute" onClick={handleCompute}/>
            </div>

            <Groups primaryKeys={primaryKeys} computeState={computeState} form={form}/>
            <Volumetry form={form}/>
        </Tab>
    )
}
