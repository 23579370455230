// App.tsx

import React from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '../styles/size.css';
import '../styles/color.css';
import '../styles/App.css';

import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import FillForm from './FillForm.tsx/FillForm';
import Landing from './Landing/Landing';
import Dashboard from './Dashboard/Dashboard';
import { LangContextProvider } from '../contexts/LangContext';


function Content() {
    return (
        <>
            <Helmet>
                <meta name="theme-color" content="rgb(253, 244, 227)"/>
            </Helmet>

            <div className="page">
                <Routes>
                    <Route path="/" element={<Landing/>}/>

                    <Route path="/signIn" element={<SignIn/>}/>
                    <Route path="/signUp" element={<SignUp/>}/>

                    <Route path="/fillForm/:uuid" element={<FillForm/>}/>

                    <Route path="/dashboard/*" element={<Dashboard/>}/>
                </Routes>
            </div>
        </>
    )
}


export default function App() {
    return (
        <BrowserRouter>
            <LangContextProvider>
                <Content/>
            </LangContextProvider>
        </BrowserRouter>
    );
}
