// Attendees.tsx

import React, { useState } from "react";
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";

import Tab from "../../Utilities/Tab";
import Button from "../../Utilities/Button";
import Attendee from "./Attendee";

import { addAttendees } from "../../../helpers/attendees";
import { sendForm } from "../../../helpers/forms";

import { AttendeeType, AttendeeWithId } from "../../../types/Attendee";

import "../../../styles/Attendees.css";


export default function Attendees() {
    const { formId } = useParams<{ formId: string }>();

    const initialValues : AttendeeType = {
        firstName : '',
        lastName : '',
        email : ''
    };

    const [attendees, setAttendees] = useState<AttendeeWithId[]>([]);

    function addAttendee() {
        setAttendees([...attendees, { ...initialValues, id: uuidv4() }]);
    }

    const validationSchema : Yup.ObjectSchema<AttendeeType> = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required')
    });

    function submitAttendees() {
        addAttendees(
            attendees.map(({ firstName, lastName, email }) => ({ firstName, lastName, email })),
            Number(formId)
        );
    }

    function handleSendForm() {
        sendForm(Number(formId));
    }
    
    return (
        <Tab title="Attendees">
            <div className="widget">
                <h1>Attendees</h1>
                
                <Button title="Add New Attendee" onClick={addAttendee} />

                <div className="attendees">
                    {attendees.map((attendee) => {
                        return (
                            <Attendee
                                key={attendee.id} // Utilisation de l'id unique comme clé
                                attendee={attendee}
                                attendees={attendees}
                                setAttendees={setAttendees}
                                validationSchema={validationSchema}
                            />
                        );
                    })}
                </div>

                <Button title="Update Attendees" onClick={submitAttendees}/>

                <Button title="Send Form" onClick={handleSendForm}/>
            </div>
        </Tab>
    );
}
