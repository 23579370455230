import React from "react";

import { LangData } from "../Utilities/Props";
import { useLangContext } from "../../contexts/LangContext";

import "../../styles/Selector.css";

interface SelectorProps {
    selected: string;
    setSelected: (selected : string) => void;
    data : Record<string, LangData>;
    className?: string;
}

export default function PricingTypeSelector({ selected, setSelected, data, className } : SelectorProps) {
    const lang = useLangContext().lang;

    return (
        <div className={className? `selector-container ${className}` : "selector-container"}>
            <div className="selector">
                {
                    Object.keys(data).map((key, index) =>
                        <span key={index} onClick={() => setSelected(key)} className={selected === key ? 'selected' : ''}>
                            {data[key][lang]}
                        </span>
                    )
                }
            </div>
        </div>
    )
}
