import React from "react";

import Page from "../Utilities/Page";

import "../../styles/Landing.css";

import allData from "../../data.json";
import { useLangContext } from "../../contexts/LangContext";
import { BlocProps, LangData } from "../Utilities/Props";
import Slider from "../Utilities/Slider/Slider";
import { CardData } from "../Utilities/Slider/Card";
import Footer from "../Footer/Footer";
import Nav from "./Nav";
import Hero from "./Hero";
import UseCases from "./UseCases";
import Pricing from "./Pricing";
import AsATrainer from "./AsATrainer";


export default function Landing() {
    const data: Record<string, LangData | CardData[]> = allData["Landing"];
    const useCasesData: Record<string, LangData | CardData[] | BlocProps[]> = allData["useCases"];

    const lang = useLangContext().lang;

    return (
        <Page title="Landing">
            <Nav data={data as Record<string, LangData>}/>

            <Hero data={data as Record<string, LangData>}/>

            <h2>{(useCasesData['title'] as LangData)[lang]}</h2>
            <UseCases data={(useCasesData["briefs"]) as BlocProps[]}/>
            <Slider data={(useCasesData["cards"] as CardData[])} className="use-cases-slider"/>

            <AsATrainer data={allData["asATrainer"]}/>

            <Slider data={(data["slider"] as CardData[])}/>

            <Pricing/>

            <Footer/>
        </Page>
    );
}
