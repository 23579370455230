// answers.tsx

import axios from 'axios';

import { getApiConfig } from './utils';
import { FilterType } from '../types/Types';


export async function computeGroups(formId: number) {
    const config = getApiConfig({ endpoint: 'computeGroups' });

    const response = await axios.get(config.backendUrl,
        {
            params: {
                formId
            },
            headers: {
                Authorization: config.token
            }
        }
    );

    console.log('RESPONSE', response.data);

    return response.data;
}

export async function computeVolumetry(formId: number, filters?: FilterType[]) {
    const config = getApiConfig({ endpoint: 'computeVolumetry' });

    console.log('FILTERS', filters);

    const response = await axios.get(config.backendUrl,
        {
            params: {
                formId,
                filters
            },
            headers: {
                Authorization: config.token
            }
        }
    );

    return response.data;
}
