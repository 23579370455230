// FormsContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

import { FormType } from '../types/Form';

// Définissez un type pour votre contexte si nécessaire
interface FormsContextType {
    forms: FormType[];
    setForms: (forms: FormType[]) => void;
}


// Créez votre contexte avec une valeur par défaut
const FormsContext = createContext<FormsContextType>({
    forms: [],
    setForms: () => {},
});


// Créez un hook personnalisé pour utiliser le contexte
const useFormsContext = () => {
    const context = useContext(FormsContext);
    if (!context) {
        throw new Error('useFormsContext must be used within a FormsContextProvider');
    }
    return context;
};


// Créez un composant de fournisseur pour votre contexte
interface FormsContextProviderProps {
    children: ReactNode;
}


const FormsContextProvider: React.FC<FormsContextProviderProps> = ({ children }) => {
    // Définissez l'état ou les fonctions que vous souhaitez partager
    const [forms, setForms] = useState<FormType[]>([]);

    // Passez les valeurs à votre contexte
    const contextValue: FormsContextType = {
        forms,
        setForms,
    };

    return <FormsContext.Provider value={contextValue}>{children}</FormsContext.Provider>;
};


export { useFormsContext, FormsContextProvider };
