import React from "react";

export default function AIWriterLogo() {
    return (
        <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="ai-writer-logo">
            <path d="M17.0001 18C17.2549 18.0003 17.5001 18.0979 17.6854 18.2728C17.8708 18.4478 
                17.9823 18.687 17.9972 18.9414C18.0122 19.1958 17.9294 19.4464 17.7658 19.6418C17.6022 19.8373 17.3701 19.9629 17.1171 
                19.993L17.0001 20H10.0001C9.74517 19.9997 9.50002 19.9021 9.31469 19.7272C9.12935 19.5522 9.01782 19.313 9.00288 19.0586C8.98794 
                18.8042 9.07073 18.5536 9.23432 18.3582C9.39791 18.1627 9.62996 18.0371 9.88305 18.007L10.0001 18H17.0001ZM17.6311 
                3.368C17.8633 3.60016 18.0475 3.87578 18.1732 4.17914C18.2988 4.4825 18.3635 4.80764 18.3635 5.136C18.3635 5.46436 18.2988 
                5.7895 18.1732 6.09286C18.0475 6.39622 17.8633 6.67184 17.6311 6.904L6.73505 17.8C6.60786 17.9272 6.45882 18.0305 6.29505 
                18.105L2.49105 19.834C1.64905 20.217 0.783054 19.35 1.16605 18.508L2.89605 14.704C2.97021 14.5403 3.07318 14.3913 3.20005 
                14.264L14.0961 3.368C14.5649 2.89932 15.2006 2.63603 15.8636 2.63603C16.5265 2.63603 17.1622 2.89932 17.6311 3.368ZM15.5111 
                4.782L4.67705 15.614L4.08805 16.911L5.38405 16.321L16.2171 5.49C16.2648 5.44388 16.3029 5.3887 16.3291 5.3277C16.3553 5.2667 
                16.3691 5.20109 16.3697 5.1347C16.3703 5.06831 16.3576 5.00247 16.3325 4.94102C16.3073 4.87957 16.2702 4.82375 16.2233 4.7768C16.1763 
                4.72986 16.1205 4.69273 16.059 4.66759C15.9976 4.64245 15.9317 4.6298 15.8654 4.63037C15.799 4.63095 15.7334 4.64474 15.6724 
                4.67095C15.6114 4.69715 15.5562 4.73524 15.5101 4.783M4.00005 0C4.20865 8.32272e-05 4.41199 0.0653942 4.58162 0.18679C4.75126 
                0.308185 4.87868 0.479587 4.94605 0.677L5.07605 1.055C5.22405 1.48875 5.46946 1.88283 5.79345 2.20699C6.11743 2.53115 6.51138 
                2.77677 6.94505 2.925L7.32305 3.054C7.52025 3.12157 7.6914 3.24908 7.81257 3.41869C7.93375 3.58831 7.99889 3.79155 7.99889 
                4C7.99889 4.20845 7.93375 4.41169 7.81257 4.58131C7.6914 4.75092 7.52025 4.87843 7.32305 4.946L6.94505 5.076C6.5113 5.224 
                6.11722 5.46941 5.79306 5.79339C5.4689 6.11738 5.22328 6.51133 5.07505 6.945L4.94605 7.323C4.87848 7.5202 4.75097 7.69134 4.58136 
                7.81252C4.41175 7.93369 4.20851 7.99883 4.00005 7.99883C3.7916 7.99883 3.58836 7.93369 3.41875 7.81252C3.24913 7.69134 3.12163 
                7.5202 3.05405 7.323L2.92405 6.945C2.77606 6.51125 2.53065 6.11717 2.20666 5.79301C1.88268 5.46885 1.48873 5.22323 1.05505 5.075L0.677054 
                4.946C0.479859 4.87843 0.30871 4.75092 0.187536 4.58131C0.0663617 4.41169 0.0012207 4.20845 0.0012207 4C0.0012207 3.79155 0.0663617 
                3.58831 0.187536 3.41869C0.30871 3.24908 0.479859 3.12157 0.677054 3.054L1.05505 2.924C1.48881 2.776 1.88289 2.53059 2.20705 
                2.20661C2.53121 1.88262 2.77683 1.48867 2.92505 1.055L3.05405 0.677C3.12143 0.479587 3.24885 0.308185 3.41848 0.18679C3.58811 
                0.0653942 3.79146 8.32272e-05 4.00005 0ZM4.00005 3.196C3.7635 3.4937 3.49375 3.76344 3.19605 4C3.49472 4.23667 3.76272 4.50467 
                4.00005 4.804C4.23672 4.50533 4.50472 4.23733 4.80405 4C4.50635 3.76344 4.23661 3.4937 4.00005 3.196Z"/>
        </svg>
    )
}