// Volumetry.tsx

import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { computeVolumetry } from "../../../helpers/compute";
import { FormType } from "../../../types/Form";
import { FilterType } from "../../../types/Types";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface VolumetryChartProps {
    data : Record<string, number>;
    maxLabels? : number;
    asc: boolean;
    min : number;
    max : number;
}

function VolumetryChart({ data, maxLabels, asc, min, max } : VolumetryChartProps) {

    // Filtrer les données en fonction de la plage
    data = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value >= min && value <= max) // eslint-disable-line @typescript-eslint/no-unused-vars
    );

    const labels = Object.keys(data);
    const values = Object.values(data);

    // Créer un tableau de tuples [clé, valeur], puis trier par la valeur de manière décroissante
    let sortedData = labels
        .map((label, index) => [label, values[index]] as [string, number])
    
    if (!asc) {
        sortedData = sortedData.sort((a, b) => b[1] - a[1])
    } else {
        sortedData = sortedData.sort((a, b) => a[1] - b[1])
    }

    // Séparer les clés et valeurs triées
    let sortedLabels = sortedData.map((item) => item[0]);
    let sortedValues = sortedData.map((item) => item[1]);

    if (maxLabels) {
        sortedLabels = sortedLabels.slice(0, maxLabels);
        sortedValues = sortedValues.slice(0, maxLabels);
    }

    const chart_data = {
        labels: sortedLabels,
        datasets: [
            {
                label: "Token Frequency",
                data: sortedValues,
                backgroundColor: "rgba(75, 192, 192, 0.5)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Monthly Sales Data (2024)",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Token",
                },
                ticks: {
                    autoSkip: false,
                    minRotation: 90,
                    maxRotation: 90,
                },
            },
            y: {
                title: {
                display: true,
                text: "Frequency",
                },
                beginAtZero: true, // Le graphique commence à 0
            },
        },
    };

    return (
        <Bar data={chart_data} options={options} />
    );
}


interface VolumetryProps {
    form: FormType;
    filters?: FilterType[];
}

export default function Volumetry({ form, filters } : VolumetryProps) {
    const [ volumetry, setVolumetry ] = useState({} as Record<string, number>);
    const [ asc, setAsc ] = useState(false);
    const [ rangeValues, setRangeValues ] = useState({ min: 0, max: 0 });

    useEffect(() => {
        if (form.id) {
            computeVolumetry(form.id, filters).then((response) => {
                setVolumetry(response.count);
            });
        }
    }, [ form ]);

    function handleToggle() {
        setAsc(!asc);
    }

    useEffect(() => {
        setRangeValues({ min: 0, max: Math.max(...Object.values(volumetry)) });
    }, [ volumetry ]);


    return (
        <div className="widget volumetry">
            <h3>Volumetry</h3>

            <div className="controls">

                <div className="toggle-switch">
                    <label>
                        <input type="checkbox" checked={asc} onChange={handleToggle} />
                        <span> {asc ? "ASC" : "DES"} </span>
                    </label>
                </div>

                <div className="range">
                    <input type="number"
                        value={rangeValues.min}
                        onChange={(e) => setRangeValues({ ...rangeValues, min: parseInt(e.target.value) })}
                        min={0}
                    />
                    <input
                        type="number"
                        value={rangeValues.max}
                        onChange={(e) => setRangeValues({ ...rangeValues, max: parseInt(e.target.value) })}
                        min={0}
                    />
                </div>

            </div>
            
            <VolumetryChart
                data={volumetry}
                maxLabels={30}
                asc={asc}
                min={rangeValues.min}
                max={rangeValues.max}
            />
        </div>
    );
}