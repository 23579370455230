import React, { useEffect, useState } from 'react';
// import Button from '../../Utilities/Button';

import '../../../styles/Form.css';

export interface SizeType {
    fixe?: number;
    min?: number;
    max?: number;
}

interface SizeProps {
    size : SizeType;
    setSize: (size: SizeType) => void;
}


export default function Size({ size, setSize } : SizeProps) {
    const [ fixed, setFixed ] = useState(true);

    useEffect(() => {
        setFixed(true);
    }, []);

    return (
        <div className="size">
            {/* <div className='buttons'>
                <Button
                    title="Fixed"
                    onClick={() => setFixed(true)}
                    className={fixed ? 'selected' : 'unselected'}
                />
                <Button
                    title="Min-Max"
                    onClick={() => setFixed(false)}
                    className={!fixed ? 'selected' : 'unselected'}
                />
            </div> */}

            {fixed &&
                <div>
                    <label>Fixed Size</label>
                    <input type="number" value={size.fixe} onChange={(e) => setSize({...size, fixe: parseInt(e.target.value)})}/>
                </div>
            }

            {/* {!fixed &&
                <div>
                    <label>Min Size</label>
                    <input type="number" value={size.min} onChange={(e) => setSize({...size, min: parseInt(e.target.value)})}/>

                    <label>Max Size</label>
                    <input type="number" value={size.max} onChange={(e) => setSize({...size, max: parseInt(e.target.value)})}/>
                </div>
            } */}
        </div>
    )
}
