// Upload.tsx

import React from "react";

import UploadLogo from "../../Utilities/UploadLogo";
import { useCSVReader, formatFileSize } from 'react-papaparse';

import { UploadType, onUploadAcceptedType, UploadProps } from "../../../types/Upload";


export default function Upload({ setDataFile, setFile } : UploadProps) {
    const { CSVReader } = useCSVReader();

    function handleOnUploadAccepted({ data } : onUploadAcceptedType) {
        setDataFile(data);
    }

    function onValidator (file : File) {
        setFile(file);
    }

    return (        
        <CSVReader onUploadAccepted={handleOnUploadAccepted} validator={onValidator}>
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: UploadType) => (
                    <div {...getRootProps()} className="bloc zone">
                        {acceptedFile ? (
                            <div className="file">
                                <div className="top">
                                    <div className="info">
                                        <span className="name">{acceptedFile.name}</span>
                                        
                                        <span className="size">
                                            {formatFileSize(acceptedFile.size)}
                                        </span>
                                    </div>

                                    <div {...getRemoveFileProps()} className="remove">
                                        <Remove color='transparent'/>
                                    </div>
                                </div>

                                <div className="progressBar">
                                    <ProgressBar/>
                                </div>
                            </div>
                        ) : (
                            <>
                                <UploadLogo/>
                                <span>Drop CSV file here or click to upload</span>
                            </>
                        )}
                    </div>
                )}
            </CSVReader>
    )
}
