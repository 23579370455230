import React from "react";

import Button from "../../Utilities/Button";
import Recents from "./Recents";

import '../../../styles/SideBar.css';
import Disconnection from "../../Utilities/Disconnection";

import allData from '../../../data.json';
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";


export default function SideBar() {
    const lang = useLangContext().lang;

    const data : Record<string, LangData> = allData['SideBar'];

    return (
        <div className="sidebar">
            <Recents/>
            <Button to="/dashboard/forms" title={data['allMyForms'][lang]}/>
            <Button to="/dashboard/form" title={data['createForm'][lang]}/>
            <Button to="/dashboard/csvParser" title={data['csvParser'][lang]}/>
            
            <Button to="/dashboard/settings" title={data['settings'][lang]}/>
            <Disconnection/>
        </div>
    )
}
