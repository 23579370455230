import React from "react";

import { BlocProps } from "../Utilities/Props";
import { useLangContext } from "../../contexts/LangContext";

import "../../styles/Landing.css";
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";


interface UseCaseProps {
    data : BlocProps;
    className?: string;
}

function UseCase({ data, className } : UseCaseProps) {
    const lang = useLangContext().lang;

    return (
        <div className={className ? `use-case ${className}` : 'use-case'}>
            <span>{data.title[lang]}</span>
        </div>
    )
}


interface UseCasesProps {
    data: BlocProps[];
}

interface SliderProps extends UseCasesProps {
    direction?: "ltr" | "rtl";
    start?: number;
}


function Slider({ data, direction = 'ltr', start} : SliderProps) {
    const slipeDir = direction == "rtl" ? "ltr" : "rtl";

    return (
        <Splide
            options={{
                type: "loop", // Loop back to the beginning when reaching the end
                autoScroll: {
                    pauseOnHover: true, // Do not pause scrolling when hovering over the carousel
                    pauseOnFocus: true, // Do not pause scrolling when the carousel is focused
                    rewind: true, // Rewind to start when the end is reached
                    speed: 0.5 // Scrolling speed
                },
                pagePerView: 1, // Number of slides per page
                arrows: false, // Hide navigation arrows
                pagination: false, // Hide pagination dots

                fixedWidth: 'calc(30 * var(--scale))', // Fixed width for each slide
                display: 'flex', // Allow the carousel to grow to fill the available space
                flexDirection: 'row', // Arrange the slides horizontally
                direction: slipeDir, // Set the direction of the carousel,
                start: start
            }}
            extensions={{ AutoScroll }} // Use the AutoScroll extension
        >
            {data.map((useCase, i) => (
                <SplideSlide key={i}>
                    <UseCase data={useCase}/>
                </SplideSlide>
            ))}
        </Splide>
    )
}





export default function UseCases({ data } : UseCasesProps) {
    // on compte le nombre d'élements dans data
    const dataLength = data.length;

    // on fait une division entière par 3
    const start = Math.floor(dataLength / 3);

    return (
        <div className='usecases-container'>
            <Slider data={data} direction="rtl" start={0}/>
            <Slider data={data} start={start}/>
            <Slider data={data} direction="rtl" start={start * 2}/>
        </div>
    )
}
