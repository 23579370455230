// Footer.tsx

import React from 'react';

import '../../styles/Footer.css'
import '../../styles/size.css'
import '../../styles/color.css'

import { useLangContext } from '../../contexts/LangContext';

import allData from '../../data.json'

import Section from "./Section";
import Page from "./Page";

import CGU_FR from '../../documents/CGU_FR.md';
import ML_FR from '../../documents/ML_FR.md';
import CGU_EN from '../../documents/CGU_EN.md';
import ML_EN from '../../documents/ML_EN.md';


export default function Footer() {
    const { lang, setLang } = useLangContext();

    const data = allData['Footer'];
    const langData = allData['languages'];

    return (
        <footer>
            <div id='sections'>
                <Section title={data['legal'][lang as keyof typeof data['legal']]}>
                    <Page content={lang === 'fr' ? CGU_FR : CGU_EN} name={data['CGU'][lang as keyof typeof data['CGU']]}/>
                    <Page content={lang === 'fr' ? ML_FR : ML_EN} name={data['LegalNotice'][lang as keyof typeof data['LegalNotice']]}/>
                </Section>
                
                <Section title={langData['title'][lang as keyof typeof langData['title']]}>
                    {Object.entries(langData.lang).map(([key, value]) => (
                        <Page key={key} name={value[lang as keyof typeof value]} onClick={() => setLang(key)}/>
                    ))}
                </Section>
            </div>

            <div id="copyright">
                {data.copyright[lang as keyof typeof data.copyright]}
            </div>
        </footer>
    );
}
