// SignUp.tsx

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Page from '../Utilities/Page';
import Input from '../Utilities/Input';
import { signUp } from '../../helpers/signUp';


import '../../styles/SignUp.css';

import allData from '../../data.json';
import { LangData } from '../Utilities/Props';
import { useLangContext } from '../../contexts/LangContext';
import Button from '../Utilities/Button';
import Back from '../Utilities/Back';


export default function SignUp() {
    const data : Record<string, LangData> = allData['SignUp'];
    const lang = useLangContext().lang;

    const initialValues = {
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        email: '',
        dob: ''
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), undefined], 'Passwords must match'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        dob: Yup.date().required('Date of birth is required').max(new Date(new Date().setFullYear(new Date().getFullYear() - 16)), 'You must be at least 16 years old')
    });

    async function handleSubmit(values: typeof initialValues) {
        try {
            const response = await signUp(values);
            if (response.status === 201) {
                // Rediriger vers la page de connexion
                window.location.href = '/signIn';
            } else {
                // Afficher un message d'erreur
                console.error('Sign up failed:', response.data.message);
            }
        } catch (error) {
            console.error('An error occurred during sign up:', error);
        }
    }

    return (
        <Page title="SignUp">
            <div className='container'>
                <img src="/signup.jpg" alt="Sign Up"/>

                <div className='text'>
                    <h1>{data['title'][lang]}</h1>

                    <Back title={data['back'][lang]} className='button-signup back'/>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <div className='sub-form name'>
                                <Input htmlFor="firstName" name={data["firstName"][lang]} className='sub-form-input'/>
                                <Input htmlFor="lastName" name={data["lastName"][lang]} className='sub-form-input'/>
                            </div>
                            
                            <Input htmlFor="email" type="email" name={data["email"][lang]}/>

                            <div className='sub-form password'>
                                <Input htmlFor="password" type="password" name={data["password"][lang]} className='sub-form-input'/>
                                <Input htmlFor="confirmPassword" type="password" name={data["confirmPassword"][lang]} className='sub-form-input'/>
                            </div>

                            <Input htmlFor="dob" type="date" name={data["dob"][lang]}/>

                            <Button title={data["submit"][lang]} type="submit" className='button-signup submit-signup'/>

                            <Button to='/signIn' title={data["signIn"][lang]} className='button-signup sign-in'/>
                        </Form>
                    </Formik>
                </div>
            </div>
        </Page>
    );
}
