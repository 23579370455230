import { QuestionType } from '../types/Types';
import { GroupType } from '../types/Group';

enum ResType {
    ANSWERS = 'answers',
    ATTENDEES = 'attendees',
}

interface ComputeState {
    groups: GroupType[];
    questions: QuestionType[];
    resType: ResType;
}

const initialComputeState: ComputeState = {
    groups: [],
    questions: [],
    resType: ResType.ATTENDEES,
};

type computeAction =
    | { type: 'SET_GROUPS'; payload: GroupType[] }
    | { type: 'SET_QUESTIONS'; payload: QuestionType[] }
    | { type: 'SET_RES_TYPE'; payload: ResType };

function computeReducer(state: ComputeState = initialComputeState, action: computeAction): ComputeState {
    switch (action.type) {
        case 'SET_GROUPS':
            return { ...state, groups: action.payload };
        case 'SET_QUESTIONS':
            return { ...state, questions: action.payload };
        case 'SET_RES_TYPE':
            return { ...state, resType: action.payload };
        default:
            return state;
    }
}

export { computeReducer, initialComputeState, ResType };
export type { ComputeState, computeAction };
