// Group.tsx

import React, { useState } from "react";
import Volumetry from "./Volumetry";
import { FormType } from "../../../types/Form";
// import { FilterType } from "../../../types/Types";

interface GroupProps {
    index : number;
    group : Record<string, string>[];
    form : FormType;
}

export default function Group({ index, group, form } : GroupProps) {
    const [ selected, setSelected ] = useState<boolean>(false);

    return (
        <div className="group" onClick={() => setSelected(!selected)} style={selected ? {width : "100%"} : {}}>
            <h4>Group {index + 1}</h4>

            {group.map((attendee, attendeeIndex) => (
                <div key={attendeeIndex} className="attendee">
                    {Object.entries(attendee).map(([key, value]) => (
                        <div key={key} className="entrie">
                            <span className="key">{key}</span>
                            <span className="value">{value}</span>
                        </div>
                    ))}
                </div>
            ))}

            {selected && 
                <Volumetry form={form}
                    // filters={[FilterType.GROUP]}
                />
            }
        </div>
    );
}
