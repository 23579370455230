import React, { useEffect } from "react";

import { Helmet } from 'react-helmet';

import { usePageContext } from '../../contexts/PageContext';
import { useRootContext } from '../../contexts/RootContext';

import { PageProps } from "../../types/Page";

import '../../styles/size.css'
import '../../styles/color.css'


export default function Tab({id, title, className, canonical, appleTouchIcon, icon_16, icon_32, favicon, manifest, xIcon, shortcutIcon, children, resetScroll}: PageProps) {
    const setPage = usePageContext().setPage;
    const pageRef = useRootContext().root;

    useEffect(() => {
        setPage(id ? id : title);
    });

    useEffect(() => {
        if (resetScroll && pageRef) {
            pageRef.scrollTo(0, 0);
        }
    }, [ resetScroll ]);

    return (
        <div id={id ? id : title} className={className ? `tab ${className}` : "tab"}>
            <Helmet>
                <title>{title}</title>
                <link rel='canonical' href={canonical}/>
                <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon}/>
                <link rel="icon" type="image/png" sizes="32x32" href={icon_32}/>
                <link rel="icon" type="image/png" sizes="16x16" href={icon_16}/>
                <link id="favicon" rel="icon" href={favicon}/>
                <link rel="manifest" href={manifest}/>
                <link rel="icon" type="image/x-icon" href={xIcon? xIcon : favicon}/>
                <link rel="shortcut icon" href={shortcutIcon? shortcutIcon : favicon}/>
            </Helmet>

            {children}
        </div>
    );
}
