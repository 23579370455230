import React from "react";
import Tab from "../../Utilities/Tab";

import '../../../styles/Settings.css';
import { useLangContext } from "../../../contexts/LangContext";

import allData from '../../../data.json';

export default function Settings() {
    const { lang, setLang } = useLangContext();
    // const data = allData.Settings;
    const langData = allData.languages;

    return (
        <Tab title="Settings">
            <div className="widget">
                <h1>Settings</h1>
                
                <div>
                    <span>Language</span>
                    <select value={lang} onChange={(e) => setLang(e.target.value)}>
                        {Object.entries(langData.lang).map(([key, value]) => (
                            <option key={key} value={key}>{value[lang as keyof typeof value]}</option>
                        ))}
                    </select>
                </div>
            </div>
        </Tab>
    );
}