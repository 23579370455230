import Cookies from 'js-cookie';

interface ApiConfig {
    backendUrl: string;
    token: string | undefined;
}

interface getApiConfigProps {
    endpoint?: string;
}

export function getApiConfig({ endpoint } : getApiConfigProps): ApiConfig {
    const mode = process.env.REACT_APP_MODE;

    let baseUrl;
    if (mode && mode === 'dev') {
        baseUrl = process.env.REACT_APP_BACKEND_URL_DEV as string;
    } else {
        baseUrl = process.env.REACT_APP_BACKEND_URL as string;
    }

    const backendUrl = endpoint ? `${baseUrl}/${endpoint}/` : baseUrl as string;
    const token = Cookies.get('token');

    return {
        backendUrl,
        token
    };
}
