import React from "react";


interface PrimaryProps {
    file: string[][];
    selectedHeaders: string[];
    setSelectedHeaders: (headers: string[]) => void;
}

export default function Primary({ file, selectedHeaders, setSelectedHeaders }: PrimaryProps) {
    return (
        <div className="bloc head">
            <h2>Primary Key</h2>

            {file[0].map((header, index) => (
                <div key={index} className="checkbox-line">
                    <input type="checkbox" value={header} onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedHeaders([...selectedHeaders, header]);
                        } else {
                            setSelectedHeaders(selectedHeaders.filter((h) => h !== header));
                        }
                    }
                    }/>
                    <label>{header}</label>
                </div>
            ))}
        </div>
    )
}
