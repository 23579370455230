// Forms.tsx

import React, { useEffect } from "react";

import Tab from "../../Utilities/Tab";

import { getForms } from "../../../helpers/forms";

import FormSummary from "./FormSummary";
import Button from "../../Utilities/Button";

import "../../../styles/Forms.css";
import { useFormsContext } from "../../../contexts/FormsContext";


export default function Forms() {
    const { forms, setForms } = useFormsContext();

    function updateForms() {
        getForms().then((data) => {
            if (Array.isArray(data)) {
                const sortedData = data.sort((a, b) => (b.id || -1) - (a.id || -1));
                setForms(sortedData);
            } else {
                console.error("Les données récupérées ne sont pas un tableau :", data);
            }
        }).catch(error => {
            console.error("Erreur lors de la récupération des formulaires :", error);
        });
    }

    useEffect(() => {
        updateForms();
    }, []);

    return (
        <Tab title="Forms">
            <div className="widget container">
                <h1>Forms</h1>

                <Button title="Refresh" onClick={updateForms}/>

                {forms.map((form, index) => (
                    <FormSummary key={index} form={form} callBack={updateForms}/>
                ))}

            </div>
        </Tab>
    );
}
