import React from 'react';

import '../../styles/icon.css';


export default function HarmateLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 -10 302.0 210.0" className='harmate-logo'>
            <polygon points="0 90.0, 90 45, 90 135.0" className='principal'/>
            <polygon points="110 90.0, 170.0 65, 170.0 115.0"  className='secondary'/>
            <polygon points="190.0 50, 262.0 90.0, 190.0 130.0" className='secondary'/>
            <path d="M 226.0 90.0 L 262.0 50 L 196.0 10" className='antenna'/>
            <path d="M 226.0 90.0 L 262.0 130.0 L 196.0 170.0" className='antenna'/>
        </svg>
    )
}
