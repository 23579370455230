import React from "react";

import '../../styles/icon.css';

export default function InformationLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='information-logo'>
                <circle cx="12" cy="12" r="9" />
                <path d="M12 8h.01v.01H12z"/>
                <path d="M12 12v4"/>
        </svg>
    )
}