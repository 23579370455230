import axios from 'axios';

import { getApiConfig } from './utils';

import { AttendeeType } from '../types/Attendee';

export async function addAttendees(attendees : AttendeeType[], formId : number) {
    const config = getApiConfig({ endpoint: 'addAttendees' });

    await axios.post(config.backendUrl, {attendees, "formId" : formId}, {
        headers: {
            Authorization: config.token
        }
    });
}
