import React from "react";

import { deleteForm } from "../../../helpers/forms";
import { FormType } from "../../../types/Form";
import Button from "../../Utilities/Button";

import "../../../styles/Forms.css";


interface FormSummaryProps {
    form: FormType;
    callBack: () => void;
}


export default function FormSummary({ form, callBack} : FormSummaryProps) {

    function handleDelete() {
        deleteForm(form.id || -1, form.creator_id || -1).then(() => {
            callBack();
        });
    }

    return (
        <div key={form.id} className="form-summary">
            <div>
                <h2>{form.title}</h2>
                <p>{form.description}</p>
                <p>{form.date}</p>
            </div>

            <div className="form-status-container">
                <p>{form.status}</p>
            </div>

            <Button title={'details'} to={`/dashboard/results/${form.id}`}/>

            <Button title={"delete"} onClick={handleDelete}/>
        </div>
    )
}
