import React from "react";

import "../../styles/Landing.css";
import { LangData } from "../Utilities/Props";
import { useLangContext } from "../../contexts/LangContext";
import { PricingPlan } from "../../types/Plan";


interface PricingCardProps {
    data : PricingPlan;
    period: string;
    priceColor?: string;
}

export default function PricingCard({ data, period, priceColor } : PricingCardProps) {
    const lang = useLangContext().lang;

    return (
        <div className="pricing-card">
            {data.title && <h2>{(data.title as LangData)[lang]}</h2>}

            {data.description && <span>{(data.description as LangData)[lang]}</span>}

            
            <div className="price-container">
                {data.price && 
                    <h3 className="price" style={priceColor ? {color: priceColor} : {}}>
                        {(data.price as LangData)[lang]}
                    </h3>
                }

                {data.monthlyPrice && period === "monthly" && 
                    <h3 className="price monthly-price" style={priceColor ? {color: priceColor} : {}}>
                        {(data.monthlyPrice as LangData)[lang]}
                    </h3>
                }

                {data.monthlyPrice && data.yearlyPrice && period === "yearly" &&
                    <>
                        <h3 className="monthly-price crossed-out">
                            {(data.monthlyPrice as LangData)[lang]}
                        </h3>

                        <h3 className="price yearly-price" style={priceColor ? {color: priceColor} : {}}>
                            {(data.yearlyPrice as LangData)[lang]}
                        </h3>
                    </>
                }

                {!data.monthlyPrice && data.yearlyPrice && period === "yearly" &&
                    <h3 className="yearly-price" style={priceColor ? {color: priceColor} : {}}>
                        {(data.yearlyPrice as LangData)[lang]}
                    </h3>
                }
            </div>

            {data.features && (data.features as LangData[]).length > 0 &&
                <ul  className="features">
                    {(data.features as LangData[]).map((feature, index) =>
                        <li key={index}>{feature[lang]}</li>
                    )}
                </ul>
            }
        </div>
    )
}
