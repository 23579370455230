import axios from "axios";
import { getApiConfig } from "./utils";


export async function signUp({firstName, lastName, password, email, dob}: {firstName: string, lastName: string, password: string, email: string, dob: string}) {
    const config = getApiConfig({ endpoint: 'signup' });

    const data = {
        first_name: firstName,
        last_name: lastName,
        password: password,
        email: email,
        dob: dob
    };

    const response = await axios.post(config.backendUrl, data);

    return response;
}
